import * as api from "api"
import slugify from "common/utils/slugify"
import nextLinks from "next.links"

export interface NavigationSubLink {
  title: string
  link: string
  subLink?: string
}

export interface NavigationLink {
  title: string
  link: string | NavigationSubLink[]
  subLink?: string
}

const NavigationLinks = (models: api.ModelsVehicle[]) => {
  const modelItems = models.map((model) => ({
    title: model.name.toUpperCase(),
    link: `/${model.id}/${encodeURIComponent(slugify(model.name))}`,
  }))

  const AboutLinks = [
    { title: "מפת האתר", link: nextLinks.sitemap },
    { title: "הצהרת נגישות", link: nextLinks.accessibility },
    { title: "תנאי שימוש", link: nextLinks.termsOfUse },
    { title: "הצהרת פרטיות", link: nextLinks.privacyPolicy },
  ]

  const Links = {
    Homepage: {
      title: "דף הבית",
      link: [
        { title: "דף הבית", link: "/" },
        { title: "לובי דגמים", link: nextLinks.models },
      ],
    },
    ContactUs: {
      title: "צור קשר",
      link: nextLinks.contactUs,
    },
    ParentModels: {
      title: "דגמי אב",
      link: modelItems,
    },
    CustomerService: {
      title: "שירות לקוחות",
      link: [
        {
          title: "חיפוש בדיקת הארכת אחריות",
          link: nextLinks.warrantyExtensions,
        },
        { title: "מחלקה ראשונה", link: nextLinks.firstClass },
        {
          title: "מרכזי שירות",
          link: nextLinks.serviceCenters,
          subLink: nextLinks.serviceCenter,
        },
        { title: "מחירון מסחריות", link: nextLinks.transitPrices },
        { title: "מחירון מוצרי תעבורה", link: nextLinks.replacements },
        { title: "קריאות חוזרות", link: nextLinks.recall },
        { title: "כתב אחריות", link: nextLinks.warranty },
        { title: "תוכניות שירות", link: nextLinks.servicePlans },
      ],
    },
    About: {
      title: "אודות",
      link: AboutLinks,
    },
    Models: {
      title: "דגמים",
      link: nextLinks.models,
      subLink: "/[modelId]/[modelSlug]",
    },
    Ford: { title: `פורד פרטיות`, link: `https://www.ford.co.il/` },
    FooterMobile: {
      title: "",
      link: [
        { title: "דגמי מסחריות", link: nextLinks.models },
        { title: "מחירון מסחריות", link: nextLinks.transitPrices },
        ...AboutLinks,
      ],
    },
  }

  return Links
}

export type Links = ReturnType<typeof NavigationLinks>

export default NavigationLinks
