const nextLinks = {
  accessibility: "/הצהרת-נגישות",
  contactUs: "/צור-קשר",
  firstClass: "/מחלקה-ראשונה",
  models: "/דגמים",
  privacyPolicy: "/הצהרת-פרטיות",
  recall: "/קריאות-חוזרות",
  replacements: "/מחירון-מוצרי-תעבורה",
  serviceCenter: "/מרכזי-שירות/[serviceCenterId]/[serviceCenterSlug]",
  serviceCenters: "/מרכזי-שירות",
  sitemap: "/מפת-אתר",
  termsOfUse: "/תנאי-שימוש",
  transitPrices: "/מחירון-מסחריות",
  warrantyExtensions: "/חיפוש-בדיקת-הארכת-אחריות",
  warranty: "/כתב-אחריות",
  servicePlans: "/תוכניות-שירות",
}

module.exports = nextLinks
